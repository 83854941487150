<template>
  <div>
    <a-row style="margin-bottom: 10px">
      广告名称:
      <a-input
        placeholder="请输入广告名称"
        v-model="searchParams.ad_name"
        style="width: 220px; margin-right: 20px"></a-input>

      状态:
      <a-select placeholder="请选择状态" v-model="searchParams.status" style="width: 180px; margin-right: 20px">
        <a-select-option :value="0">全部</a-select-option>
        <a-select-option :value="1">生效中</a-select-option>
        <a-select-option :value="2">未生效</a-select-option>
        <a-select-option :value="4">已结束</a-select-option>
      </a-select>

      创建时间:
      <a-date-picker
        :locale="locale"
        :allowClear="false"
        v-model="start_time"
        placeholder="请选择"
        @change="onStartChange"
        style="width: 200px" />

      <a-button @click="getList(true)" style="margin-left: 10px">查询</a-button>
      <a-button @click="reSearch()" style="margin-left: 10px">重置</a-button>
    </a-row>
    <!-- @click="add" -->
    <a-button type="primary" icon="plus" @click="add">新增广告</a-button>

    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="dataList"
      :rowKey="record => record.ad_id"
      tableLayout="fixed"
      :pagination="{
        current: searchParams.page,
        pageSize: searchParams.limit,
        total: total,
        showLessItems: true,
        showQuickJumper: true,
        showTotal: (total, range) => `总计 ${total} 条`,
        onChange: onPageChange,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'],
        onShowSizeChange: onPageChange,
      }">
      <template slot="action" slot-scope="text, record">
        <a-popconfirm
          v-if="record.status == 1"
          title="确定终止该广告吗?"
          ok-text="是"
          cancel-text="否"
          @confirm="homeAdstop(record.ad_id)">
          <a style="margin-right: 10px">手动结束</a>
        </a-popconfirm>
        <a @click="edit(record.ad_id)" v-if="record.status != 4" style="margin-right: 10px">编辑</a>
        <a-popconfirm title="确定删除该广告吗?" ok-text="是" cancel-text="否" @confirm="confirm(record.ad_id)">
          <a class="error-color">删除</a>
        </a-popconfirm>
      </template>
    </a-table>
  </div>
</template>

<script>
import zhCn from "ant-design-vue/es/date-picker/locale/zh_CN";
import "moment/dist/locale/zh-cn";
import moment from "moment";
import { mixins } from "../../mixin/index";
import { homeAdlist, homeAddelete, homeAdstop } from "@/views/api/system";

export default {
  mixins: [mixins],

  data() {
    return {
      locale: zhCn,
      start_time: null,
      columns: [
        {
          title: "广告名称",
          dataIndex: "ad_name",
        },
        {
          title: "开始时间",
          dataIndex: "start_time",
        },
        {
          title: "结束时间",
          dataIndex: "end_time",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
        },
        {
          title: "状态",
          dataIndex: "status_text",
        },
        {
          title: "操作",
          scopedSlots: {
            customRender: "action",
          },
        },
      ],
    };
  },

  methods: {
    getList() {
      this.loading = true;
      this.searchParams.start_time = this.start_time ? moment(this.start_time).unix() : null;
      this.searchParams.end_time = this.start_time ? moment(this.start_time).unix() : null;
      homeAdlist(this.searchParams).then(res => {
        this.dataList = res.data.list;
        this.total = res.data.count;
        this.loading = false;
      });
    },

    reSearch() {
      this.searchParams = {};
      this.searchParams.page = 1;
      this.searchParams.limit = 10;
      this.start_time = undefined;
      this.getList();
    },

    onStartChange(date, dateString) {
      this.start_time = moment(dateString);
      this.getList();
    },

    add() {
      this.$router.push({
        path: `/addHomeAds`,
      });
    },

    // 添加编辑跳转
    edit(ad_id) {
      this.$router.push({
        path: `/addHomeAds`,
        query: { ad_id: ad_id },
      });
    },

    // 删除
    confirm(ad_id) {
      homeAddelete({ ad_id }).then(res => this.handleSuccess(3, res));
    },

    // 终止
    homeAdstop(ad_id) {
      homeAdstop({ ad_id }).then(res => {
        if (res.code == 200) {
          this.$message.success(`已终止`);
          this.getList();
        }
      });
    },

    // 操作成功
    handleSuccess(flag, res) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? "添加" : flag == 2 ? "编辑" : flag == 3 ? "删除" : "修改状态"}成功`);
        this.visible = false;
        this.getList();
      }
    },
  },
  created() {
    this.searchParams.status = 0;
  },
};
</script>
<style lang="less" scoped></style>
